import './App.css';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import PrivateComponent from './Components/private/PrivateComponent';
import Login from './Components/auth/Login';
import ForgotPassword from './Components/auth/ForgotPassword';
import GuestUser from './Components/auth/GuestUser';
import MobilePortrait from './Components/Portrait/MobilePortrait';
import Otp from './Components/auth/Otp';
import Signup from './Components/auth/Signup';
import Password from './Components/auth/Password';
import ForgotOtp from './Components/auth/ForgotOtp';
import ResetPassword from './Components/auth/ResetPassword';
import UnityRedirect from './Components/Portrait/UnityRedirect';
import Swal from 'sweetalert2'
import {isMobile} from 'react-device-detect';


function App() {
  useEffect(() => {
  fetchConfig();
  }, [])
  const fetchConfig = async () => {
    try {
      const url = window.location.href
      const configUrlArray = url.split('/');
      if (url.indexOf("index.html") !== -1 || url.endsWith("/")) configUrlArray.pop();
      let configUrl = configUrlArray.join('/');
      configUrl = configUrl.concat('/config/config.json');
      var a = configUrl.replace(configUrlArray[4]+"/", "")
      // var a = configUrl.replace(configUrlArray[3]+"/", "")
      const response = await axios.get(a);
      localStorage.setItem('config', JSON.stringify(response.data))
    }
    catch (err) {
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Some problem has occurred. Please reload and try again.',
        toast: true,
        showConfirmButton: false,
        timer: 3000
      })
    }

  }

  return (
    <>
      <BrowserRouter basename={'/react'}>
        <Routes>
          {isMobile ? <Route path="/" element={<Login />} /> :  <Route path="/" element={<Login />} />}
          <Route element={<PrivateComponent />}>
            <Route path="/unity" element={<UnityRedirect />} />
          </Route>
         
          <Route path="/signup" element={<Signup />} />
          <Route path="/guest-user" element={<GuestUser />} />
          <Route path="/password" element={<Password />} />
          <Route path="/otp" element={<Otp />} />
          <Route path="/verify-otp" element={<ForgotOtp />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
        </Routes>
      </BrowserRouter>

    </>
  );
}

export default App;
